import './bootstrap';

import {Carousel, Modal} from '~bootstrap'

window.Carousel = Carousel;
window.Modal = Modal;

import Cookies from 'js-cookie';

window.Cookies = Cookies

import flatpickr from 'flatpickr';

import Splide from '@splidejs/splide';

import {Intersection} from '@splidejs/splide-extension-intersection';

window.Splide = Splide

window.Intersection = Intersection

import Swal from 'sweetalert2';

window.Swal = Swal;

import Plyr from 'plyr';

window.Plyr = Plyr;

import WaveSurfer from 'wavesurfer.js'

window.WaveSurfer = WaveSurfer;

import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf.mjs';
import pdfJSWorkerURL from 'pdfjs-dist/legacy/build/pdf.worker?url';
import {PDFSinglePageViewer, EventBus, PDFLinkService} from 'pdfjs-dist/web/pdf_viewer';

window.pdfJSWorkerURL = pdfJSWorkerURL;

import PhotoSwipeLightbox from 'photoswipe/lightbox';

window.PhotoSwipeLightbox = PhotoSwipeLightbox;

import PhotoSwipe from 'photoswipe';

window.PhotoSwipe = PhotoSwipe;

import PhotoSwipeVideoPlugin from 'photoswipe-video-plugin';

window.PhotoSwipeVideoPlugin = PhotoSwipeVideoPlugin;

import.meta.glob([
    '../images/**',
]);
document.addEventListener('livewire:navigated', () => {
    // Use JavaScript to handle the click event for each element
    document.querySelectorAll('.social-share-wrap').forEach(function (element) {
        element.addEventListener('click', function (event) {
            document.querySelectorAll('.social-share-wrap').forEach(function (el) {
                if (el !== element) {
                    el.classList.remove('clicked');
                }
            });
            this.classList.toggle('clicked');
            event.stopPropagation(); // Prevent the click event from propagating to the document body
        });
    });

    // Add a click event listener to the document body
    document.body.addEventListener('click', function (event) {
        // Remove the "clicked" class from all .social-share-wrap elements
        document.querySelectorAll('.social-share-wrap').forEach(function (element) {
            element.classList.remove('clicked');
        });
        let globalSearchInput = document.getElementById('global-search');
        if (event.target !== globalSearchInput) {
            globalSearchInput.value = '';
        }
        if (document.getElementById('global-search-result')) {
            document.getElementById('global-search-result').classList.add('d-none');
        }
    });

    let backToTopButton = document.getElementById('btn-back-to-top');

    window.onscroll = function () {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            backToTopButton.classList.add("show");
        } else {
            backToTopButton.classList.remove("show");
        }
    };

    backToTopButton.addEventListener('click', function () {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    });

    // Prevent zooming via touch gestures
    let initialDistance = null;

    function getDistance(touches) {
        const [touch1, touch2] = touches;
        const dx = touch1.pageX - touch2.pageX;
        const dy = touch1.pageY - touch2.pageY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    document.addEventListener('touchstart', function (event) {
        if (event.touches.length === 2) {
            initialDistance = getDistance(event.touches);
        }
    }, {passive: false});

    document.addEventListener('touchmove', function (event) {
        if (event.touches.length === 2) {
            const currentDistance = getDistance(event.touches);
            const scale = currentDistance / initialDistance;

            if (scale !== 1) {
                event.preventDefault();
            }
        }
    }, {passive: false});

});
